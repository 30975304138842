import React from 'react';
import { Link, Route } from "react-router-dom";
import Logo from "../../../assets/images/dkgcrmlogo.png";
import Whatsapps from "../../../assets/images/whatsapps.png";
import './style.scss';
import { FaQuestion } from "react-icons/fa";
// import { ExternalLink } from 'react-external-link';
import './mobile-header.scss'

//import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import UserNav from "./userNav/JobseekerNav";
//import MainTitle from './title';
//import ClockCounterModal from './modal/clockcounter/ClockCounter'
//import WorkFlowModal from './modal/workplan/WorkplanToday'
import { useSelector } from "react-redux";
//import AnnouncementModal from './modal/announcements/Announcements';
import CandidatePrescreenModal from './modal/candidateprescreen/CandidatePrescreen'
import ActionRecordModal from './modal/actionrecord/ActionRecord'
import NotificationModal from './modal/notification/NotificationModal'
import EnvelopeModal from './modal/envelope/EnvelopeModal'
import TaskModal from './modal/taskmodal/TaskModal'
import PushNotification from '../../pages/pushnotification/PushNotification';
import DuplicateCheck from '../../pages/candidate/pipeline/modal/duplicateCheck';

const MyAccHeader = (props) => {
    const [showAlert, setShowAlert] = React.useState(false)

    const showShowAlert = () => {
        setShowAlert(true)
    }
    const handleExternalLink = () => {
        window.location.href = 'https://wa.me/353830849152?text=Hi DK Global Recruitment';
    }
    const { userInfo } = useSelector((state) => state.auth);

    return (
        <React.Fragment>
            <div className={`dk-mdHeaderMain dk-dkgcrm-header ${props.dkgmainheaader}`}>
                <div className="d-flex align-items-center justify-content-between dk-mbtopheaderCon">
                    <div className="dk-moduleLogo">
                        <Route>
                            {
                                userInfo.role === "candidate" ? <Link to="/js-account/dashboard" style={{ paddingLeft: "1.25rem" }}>
                                    <img src={Logo} className="img-fluid" alt="" />
                                </Link>
                                    :
                                    <Link to="/dashboard/plateform" style={{ paddingLeft: "1.25rem" }}>
                                        <img src={Logo} className="img-fluid" alt=""  />
                                    </Link>
                            }

                        </Route>
                    </div>
                    <div className="headerTitle" style={{ color: props.textcolor }}>
                        <Link to={props.dashLink} style={{ color: props.linkcolor }} className="mr-2">
                            {props.dashTitle}
                        </Link>
                        <Link to={props.dashboardLink} style={{ color: props.linkcolor }} className="mr-2">
                            {props.dashboardheaderLink}
                        </Link>
                        <Link to={props.moduleLink} style={{ color: props.linkcolor }}>
                            {props.mainheaderLink}
                        </Link>
                        {/* {props.mainheaderTitle} */}
                        MANAGEMENT ACCOUNT
                    </div>

                    <div className="dk-mdHeader-rightPanel">
                        <div className='dk-btnsClient d-flex'>
                            {/* <Link to="https://wa.me/917518724208?text=Hi..." target="_blank" className="dk-unreadEmails"> */}
                            <Link to="#" target='_blank' className="dk-unreadEmails" onClick={handleExternalLink}>
                                <img src={Whatsapps} className='dkg-whatsapp-icon' alt='Whatsapp-1' />
                            </Link>
                            {/* <ExternalLink href="https://wa.me/353830849152?text=Hi DK Global Recruitment">
                              <img src={Whatsapps}  className='dkg-whatsapp-icon'  alt='Whatsapp'/>
                            </ExternalLink> */}
                        </div>
                        <div className={`dk-btnsClient d-flex ${props.myaccheadericons}`}>
                            <Route>
                                {/* <WorkFlowModal /> */}
                                {/* <AnnouncementModal />
                                */}
                                <EnvelopeModal />
                                <NotificationModal />
                                <DuplicateCheck />
                                {/* <CandidatePrescreenModal /> */}
                                <ActionRecordModal />
                                <TaskModal />
                                <Link to="#" className="dk-unreadEmails" title="Question"><FaQuestion /></Link>
                            </Route>
                        </div>
                        <UserNav />
                        {/* <ArrowDropDownIcon /> */}
                    </div>
                </div>
            </div>
            <div className={`dk-mdHeaderMain dk-dkgcrm-header dkg-crm-mobile-header d-none ${props.dkgmainheaader}`}>
                <div className="d-flex align-items-center justify-content-between dk-mbtopheaderCon">
                    <div className="dk-moduleLogo">
                        <Route>
                            {
                                userInfo.role === "candidate" ? <Link to="/js-account/dashboard" style={{ paddingLeft: "0.5rem" }}>
                                    <img src={Logo} className="img-fluid" alt="" style={{ height: "50px" }} />
                                </Link>
                                    :
                                    <Link to="/dashboard/plateform" style={{ paddingLeft: "0.5rem" }}>
                                        <img src={Logo} className="img-fluid" alt="" style={{ height: "50px" }} />
                                    </Link>
                            }
                        </Route>
                    </div>
                    <div className='dkg-mobile-userNav'>
                        <UserNav />
                    </div>
                    <div className="headerTitle" style={{ color: props.textcolor }}>
                        <Link to={props.dashLink} style={{ color: props.linkcolor }} className="mr-2">
                            {props.dashTitle}
                        </Link>
                        <Link to={props.dashboardLink} style={{ color: props.linkcolor }} className="mr-2">
                            {props.dashboardheaderLink}
                        </Link>
                        <Link to={props.moduleLink} style={{ color: props.linkcolor }}>
                            {props.mainheaderLink}
                        </Link>
                        {props.mainheaderTitle}
                    </div>

                    <div className="dk-mdHeader-rightPanel">
                        <div className={`dk-btnsClient d-flex ${props.myaccheadericons}`}>
                            <Route>
                                {/* <WorkFlowModal /> */}
                                {/* <AnnouncementModal />
                                */}
                                <DuplicateCheck />
                                <CandidatePrescreenModal />
                                {/* <NotificationModal /> */}
                                <ActionRecordModal />
                                <TaskModal />
                                <EnvelopeModal />
                            </Route>
                        </div>
                        {/* <ArrowDropDownIcon /> */}
                    </div>
                </div>
            </div>
            {showAlert ? <PushNotification /> : null}
            <Link to="#" onClick={showShowAlert} className="dk-click">Click</Link>

        </React.Fragment>
    );
}

export default MyAccHeader;