import React from 'react';
import { Link } from 'react-router-dom'
import Logo from "../../../assets/images/dkgcrmlogo.png";
import './style.scss';
import { useSelector } from "react-redux";
import LoginFaqModal from '../../pages/login/faq/LoginFaq'

const LoginmoHeader = () => {
    const[isLogin, setIsLogin] = React.useState(false);
    const showloginModal = () =>{
        setIsLogin(true);
    }
    const hideloginModal = () =>{
        setIsLogin(false);
    }
     const handleExternalLink = () => {
        window.location.href = 'https://dkglobalcrm.com/';
    }
  return (
    <>
        {
            (isLogin) ? <LoginFaqModal onHide={hideloginModal} /> : null
        }
        <div className="dkg-candmo-header">
            <section className="dkg-candmo-wrapper dkg-candmo-container">
                <Link to="#"  className="dkg-candmo-brand" onClick={handleExternalLink} >
                    <img src={Logo} className="img-fluid" alt="DKG CRM Logo"  />
                </Link>
                <div className="dkg-myacc-userinfo">
                    <div className="dkg-login-faq-btnCon">
                        <button className='dkg-login-faq-btn btn' onClick={showloginModal}>
                        Login FAQ's <span className='dkg-login-info-icon'>
                            {/* <FaInfoCircle  /> */}
                        </span>
                        </button>
                    </div>
                </div>
            </section>
        </div>
    </>
  )
}

export default LoginmoHeader